<template>
  <div class="container">
    <div class="main">
      <top-timer></top-timer>
      <div class="dohand">
        <div class="preset">预设</div>
        <div class="amount">金额<input type="text" /></div>
        <div class="btn none">确认</div>
        <div class="btn yes">重置</div>
        <div class="btn2 a">结果走势</div>
        <!---->
      </div>
      <div class="player listmain type-ssg lgklsf game-lgklsf game-ssg-lh">
        <div class="data clh_l">
          <h3>龙虎</h3>
          <ul>
            <li balls="lh_l" itid="24636" code="lh|Z1-Z2" id="lh|Z1-Z2_24636">
              <ol class="td_name">1-2</ol>
              <ol id="rate_24636" class="td_rate">--</ol>
              <ol class="td_cash lianTip"><input once_max="0" name="cash_24636" id="cash_24636" type="text"></ol>
            </li>
          </ul>
        </div>
        <div class="data clh_h">
          <h3>虎</h3>
          <ul>
            <li balls="lh_h" itid="24637" code="lh|Z1-Z2" id="lh|Z1-Z2_24637">
              <ol id="rate_24637" class="td_rate">--</ol>
              <ol class="td_cash"><input once_max="0" name="cash_24637" id="cash_24637" type="text"></ol>
            </li>
          </ul>

        </div>

      </div>
      <yushe :disalbed="isDisabled" />
      <div class="roadmap">
        <table class="ltbinfo">
          <tbody>
            <tr>
              <th data-code="lh_b1-b2" name="lh_b1-b2" class="">1-2</th>
              <th data-code="lh_b1-b3" name="lh_b1-b3" class="">1-3</th>
              <th data-code="lh_b1-b4" name="lh_b1-b4">1-4</th>
              <th data-code="lh_b1-b5" name="lh_b1-b5">1-5</th>
              <th data-code="lh_b1-b6" name="lh_b1-b6">1-6</th>
              <th data-code="lh_b1-b7" name="lh_b1-b7">1-7</th>
              <th data-code="lh_b1-b8" name="lh_b1-b8">1-8</th>
              <th data-code="lh_b2-b3" name="lh_b2-b3">2-3</th>
              <th data-code="lh_b2-b4" name="lh_b2-b4">2-4</th>
              <th data-code="lh_b2-b5" name="lh_b2-b5">2-5</th>
              <th data-code="lh_b2-b6" name="lh_b2-b6">2-6</th>
              <th data-code="lh_b2-b7" name="lh_b2-b7">2-7</th>
              <th data-code="lh_b2-b8" name="lh_b2-b8">2-8</th>
              <th data-code="lh_b3-b4" name="lh_b3-b4">3-4</th>
            </tr>
            <tr>
              <th data-code="lh_b3-b5" name="lh_b3-b5">3-5</th>
              <th data-code="lh_b3-b6" name="lh_b3-b6" class="selected">3-6</th>
              <th data-code="lh_b3-b7" name="lh_b3-b7">3-7</th>
              <th data-code="lh_b3-b8" name="lh_b3-b8">3-8</th>
              <th data-code="lh_b4-b5" name="lh_b4-b5">4-5</th>
              <th data-code="lh_b4-b6" name="lh_b4-b6">4-6</th>
              <th data-code="lh_b4-b7" name="lh_b4-b7">4-7</th>
              <th data-code="lh_b4-b8" name="lh_b4-b8">4-8</th>
              <th data-code="lh_b5-b6" name="lh_b5-b6">5-6</th>
              <th data-code="lh_b5-b7" name="lh_b5-b7">5-7</th>
              <th data-code="lh_b5-b8" name="lh_b5-b8">5-8</th>
              <th data-code="lh_b6-b7" name="lh_b6-b7">6-7</th>
              <th data-code="lh_b6-b8" name="lh_b6-b8">6-8</th>
              <th data-code="lh_b7-b8" name="lh_b7-b8">7-8</th>
            </tr>
          </tbody>
        </table>
        <!-- -- -->
        <table colspan="0" id="lt_lh_b1-b2" cellspan="0" class="lttab" style="display: table;">
          <tbody>
            <tr>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
            </tr>
            <tr>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td>　</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td>　</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b2_l">龙</td>
              <td>　</td>
              <td class="lh|b1-b2_l">龙</td>
              <td>　</td>
              <td class="lh|b1-b2_l">龙</td>
              <td>　</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_h">虎</td>
              <td>　</td>
              <td class="lh|b1-b2_l">龙</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_l">龙</td>
              <td class="lh|b1-b2_h">虎</td>
              <td>　</td>
            </tr>
            <tr>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b2_h">虎</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b2_h">虎</td>
              <td>　</td>
              <td class="lh|b1-b2_l">龙</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b2_l">龙</td>
              <td>　</td>
              <td class="lh|b1-b2_l">龙</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_h">虎</td>
              <td>　</td>
              <td class="lh|b1-b2_l">龙</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b2_l">龙</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b2_l">龙</td>
              <td>　</td>
              <td>　</td>
            </tr>
            <tr>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b2_h">虎</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b2_h">虎</td>
              <td>　</td>
              <td class="lh|b1-b2_l">龙</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_h">虎</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b2_l">龙</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
            </tr>
            <tr>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b2_h">虎</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b2_h">虎</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td class="lh|b1-b2_h">虎</td>
              <td class="lh|b1-b2_h">虎</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
              <td>　</td>
            </tr>
          </tbody>
        </table>

        <!-- --- -->
      </div>
    </div>
    <div class="other">
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist">
            <li>
              <span>1-2【龙】</span>
              <b class="blue">10期</b>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
export default {
  name: "",
  props: [""],
  data() {
    return {
      tableType: 1,
      // 长龙 tab
      changelongActive: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [],
    };
  },
  components: {
    yushe,
    topTimer,
  },
  watch: {
    amount(val) {
      this.rowsData.forEach((item) => {
        if (item.selected) {
          item.cash = val;
        }
      });
      this.$forceUpdate();
    },
  },
  created() {},
  computed: {
    //  预设按钮是否能点击 筛选数据传到下注弹框
    isDisabled() {
      let b = true;
      this.xiazhuList = [];
      this.rowsData.forEach((item) => {
        if (item.cash) {
          this.xiazhuList.push(item);
          b = false;
        }
      });
      if (!b) {
        this.$store.commit("setXZList", this.xiazhuList);
      } else {
        this.$store.commit("setXZList", []);
      }
      return b;
    },
    ...mapState(["amount", "quickSelectedData", "quick_c", "quick_b"]),
  },

  methods: {
    // 切换 不一个类型得 表格
    setTableType(num) {
      this.tableType = num;
    },
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
    closeCheck(ele) {
      ele.b = false;
      this.$forceUpdate();
    },
    // input聚焦 判断显示快速下注
    showOrHideCheck(e, obj) {
      if (this.quick_b && this.quick_c) {
        this.rowsData.forEach((item) => {
          if (item.paiming == obj.paiming) {
            item.b = true;
          } else {
            item.b = false;
          }
        });
        this.$forceUpdate();
      }
    },
    // 选中快捷金额
    setCurrentName(item, num) {
      item.cash = num;
      item.b = false;
      console.log(item, num);
      this.$forceUpdate();
    },
    closeCheck(ele) {
      ele.b = false;
      this.$forceUpdate();
    },
    //   点击当前li
    setCurrentSelected(ele) {
      ele.selected = !ele.selected;
      ele.cash = ele.selected ? this.amount : "";
      this.$forceUpdate();
    },
  },
};
</script>
<style  scoped>
.listmain .data ul li {
  width: 100%;
}
</style>